import axios from '@/api/axios';
import lang from '@/lang';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    catalogs: [],
    catalogDetails: undefined,
  },
})
  .get({
    action: 'getShopConfig',
    path: () => '/shop/config',
  })
  .put({
    action: 'toggleShopEnabled',
    path: () => '/shop/config/enabled',
  })
  .get({
    action: 'getShopCatalogs',
    path: () => '/shop/catalogs',
    property: 'catalogs',
  })
  .get({
    action: 'getShopCatalog',
    path: ({ catalogId }) => `/shop/catalogs/${catalogId}`,
    property: 'catalogDetails',
    beforeRequest: (state, { params }) => {
      if (state.catalogDetails && state.catalogDetails.id !== params.catalogId) {
        state.catalogDetails = undefined;
      }
    },
  })
  .put({
    action: 'updateShopCatalog',
    path: ({ id }) => `/shop/catalogs/${id}`,
    onSuccess: (s, { data }, a, { params }) => {
      const index = s.catalogs.findIndex((x) => x.id === params.id);
      Vue.set(s.catalogs, index, data);
    },
  })
  .post({
    action: 'createShopCatalog',
    path: () => '/shop/catalogs',
    onSuccess: (s, { data }) => {
      s.catalogs.push(data);
    },
  })
  .delete({
    action: 'archiveShopCatalog',
    path: ({ catalogId }) => `/shop/catalogs/${catalogId}`,
    onSuccess: (s, _, a, { params }) => {
      s.catalogs = s.catalogs.filter((x) => x.id !== params.catalogId);
    },
  })
  .post({
    action: 'createShopSubCatalog',
    path: ({ catalogId }) => `/shop/catalogs/${catalogId}/subcatalogs`,
    onSuccess: (s, { data }, a, { params }) => {
      if (s.catalogDetails.id === params.catalogId) {
        s.catalogDetails = data;
      }
    },
  })
  .delete({
    action: 'deleteShopSubCatalog',
    path: ({ catalogId, subCatalogId }) => `/shop/catalogs/${catalogId}/subcatalogs/${subCatalogId}`,
    onSuccess: (s, _, a, { params }) => {
      s.catalogDetails = s.catalogDetails.filter((x) => x.id !== params.subCatalogId);
    },
  })
  .put({
    action: 'updateShopSubCatalog',
    path: ({ catalogId, subCatalogId }) => `/shop/catalogs/${catalogId}/subcatalogs/${subCatalogId}`,
    onSuccess: (s, { data }, a, { params }) => {
      if (s.catalogDetails.id === params.catalogId) {
        s.catalogDetails = data;
      }
    },
  })
  .put({
    action: 'updateShopSubCatalogsOrder',
    path: ({ catalogId }) => `/shop/catalogs/${catalogId}/subcatalogs/order`,
    onSuccess: (s, _, a, { data }) => {
      s.catalogDetails.subCatalogs.forEach((x) => {
        Vue.set(x, 'ordinal', data.orderedIds.indexOf(x.subCatalogId));
      });
    },
  })

  .post({
    action: 'createShopSubCatalogNode',
    path: ({ catalogId, subCatalogId }) =>
      `/shop/catalogs/${catalogId}/subcatalogs/${subCatalogId}/nodes`,
    onSuccess: (s, { data }, a, { params }) => {
      if (s.catalogDetails.id === params.catalogId) {
        s.catalogDetails = data;
      }
    },
  })
  .put({
    action: 'updateShopSubCatalogNode',
    path: ({ catalogId, subCatalogId, nodeId }) =>
      `/shop/catalogs/${catalogId}/subcatalogs/${subCatalogId}/nodes/${nodeId}`,
    onSuccess: (s, { data }, a, { params }) => {
      if (s.catalogDetails.id === params.catalogId) {
        s.catalogDetails = data;
      }
    },
  })
  .delete({
    action: 'deleteShopSubCatalogNode',
    path: ({ catalogId, subCatalogId, nodeId }) =>
      `/shop/catalogs/${catalogId}/subcatalogs/${subCatalogId}/nodes/${nodeId}`,
    onSuccess: (s, _, a, { params }) => {
      s.catalogDetails.subCatalogNodes = s.catalogDetails.subCatalogNodes
        .filter((x) => x.nodeId !== params.nodeId);
    },
  })
  .put({
    action: 'updateShopSubCatalogNodesOrder',
    path: ({ catalogId, subCatalogId }) =>
      `/shop/catalogs/${catalogId}/subcatalogs/${subCatalogId}/nodes/order`,
    onSuccess: (s, _, a, { params, data }) => {
      s.catalogDetails.subCatalogNodes = s.catalogDetails.subCatalogNodes
        .map((x) => {
          if (x.subCatalogId === params.subCatalogId && x.parentId === data.parentId) {
            return {
              ...x,
              ordinal: data.orderedIds.indexOf(x.nodeId),
            };
          }
          return x;
        });
    },
  })

  .post({
    action: 'createOrderStatus',
    path: ({ catalogId }) =>
      `/shop/catalogs/${catalogId}/order-statuses`,
    onSuccess: (s, { data }, a, { params }) => {
      if (s.catalogDetails.id === params.catalogId) {
        Vue.set(s.catalogDetails, 'orderStatuses', data);
      }
    },
  })
  .put({
    action: 'updateOrderStatus',
    path: ({ catalogId, orderStatusId }) =>
      `/shop/catalogs/${catalogId}/order-statuses/${orderStatusId}`,
    onSuccess: (s, { data }, a, { params }) => {
      if (s.catalogDetails.id === params.catalogId) {
        Vue.set(s.catalogDetails, 'orderStatuses', data);
      }
    },
  })
  .delete({
    action: 'deleteOrderStatus',
    path: ({ catalogId, orderStatusId }) =>
      `/shop/catalogs/${catalogId}/order-statuses/${orderStatusId}`,
    onSuccess: (s, _, a, { params }) => {
      if (s.catalogDetails.id === params.catalogId) {
        s.catalogDetails.orderStatuses = s.catalogDetails.orderStatuses
          .filter((x) => x.id !== params.orderStatusId);
      }
    },
  })
  .put({
    action: 'updateOrderStatusOrder',
    path: ({ catalogId }) => `/shop/catalogs/${catalogId}/order-statuses/order`,
    onSuccess: (s, { data }, a, { params }) => {
      if (s.catalogDetails.id === params.catalogId) {
        Vue.set(s.catalogDetails, 'orderStatuses', data);
      }
    },
  })
  .getStore();

store.getters = {
  catalogs: (s) => s.catalogs,
  catalogDetails: (s) => s.catalogDetails,
  singleOrderSchema: (s) => s.catalogDetails.orderSchema === 'Single',
  layoutOptions: () => [
    {
      value: 0,
      text: lang.t('shop.subcatalog.layout.none'),
    },
    {
      value: 1,
      text: lang.t('shop.subcatalog.layout.primary'),
    },
    {
      value: 2,
      text: lang.t('shop.subcatalog.layout.secondary'),
    },
  ],
  orderSchemas: () => [
    {
      key: 'Single',
      icon: 'far fa-bag-shopping',
      text: lang.t('shop.catalog.type.single'),
      description: lang.t('shop.catalog.type.singleDescription'),
    },
    {
      key: 'Monthly',
      icon: 'far fa-calendar',
      text: lang.t('shop.catalog.type.monthly'),
      description: lang.t('shop.catalog.type.monthlyDescription'),
    },
    {
      key: 'Daily',
      icon: 'far fa-calendar-day',
      text: lang.t('shop.catalog.type.daily'),
      description: lang.t('shop.catalog.type.dailyDescription'),
    },
  ],
  productCategories: () => [
    {
      key: 'Catering',
      icon: 'fas fa-fork-knife',
      text: lang.t('shop.category.catering'),
    },
    {
      key: 'Courses',
      icon: 'fas fa-baseball-bat-ball',
      text: lang.t('shop.category.courses'),
    },
    {
      key: 'Events',
      icon: 'fas fa-people-roof',
      text: lang.t('shop.category.events'),
    },
    {
      key: 'Trips',
      icon: 'fas fa-campground',
      text: lang.t('shop.category.trips'),
    },
    {
      key: 'Items',
      icon: 'fas fa-tag',
      text: lang.t('shop.category.items'),
    },
  ],
};

export default store;
