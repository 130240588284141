import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {

  },
})
  .get({
    action: 'getAccountingConfig',
    path: 'accounting/config',
  })
  .put({
    action: 'toggleAccountingEnabled',
    path: 'accounting/config/enabled',
  })
  .put({
    action: 'updateAccountingNoteColumns',
    path: 'accounting/config/billing-notes',
  })
  .put({
    action: 'updateAccountingExportRules',
    path: 'accounting/config/export-rules',
  })
  .put({
    action: 'updateAccountingBillingCategories',
    path: 'accounting/config/billing-categories',
  })
  .get({
    action: 'getDocuments',
    path: 'accounting/documents',
  })
  .post({
    action: 'getDocumentsZipped',
    requestConfig: {
      responseType: 'blob',
    },
    path: 'accounting/documents/zipped',
  })
  .post({
    action: 'exportDocuments',
    requestConfig: {
      responseType: 'blob',
    },
    path: 'accounting/documents/export',
  })
  .get({
    action: 'getDocumentDetails',
    path: 'accounting/documents/single',
  })
  .post({
    action: 'createBillingDocumentsPreview',
    path: 'accounting/documents/bills/preview',
  })
  .post({
    action: 'createBillingDocumentFilePreview',
    path: 'accounting/documents/bills/preview/file',
  })
  .post({
    action: 'approveBillingDocuments',
    path: 'accounting/documents/bills',
  })
  .get({
    action: 'getGeneratedBillingDocuments',
    path: 'accounting/documents/bills',
  })
  .post({
    action: 'scheduleDocumentSend',
    path: 'accounting/documents/plan-delivery',
  })
  .getStore();

store.getters = {
};

export default store;
