/* eslint-disable max-len */

module.exports = {
  organization: 'Organizacja',
  person: 'Osoba',
  login: {
    login: 'Zaloguj',
    register: 'Zarejestruj',
    validated: 'Zweryfikowano',
    forgetPass: 'Zapomniałem hasła',
    emailAdress: 'Adres email',
    password: 'Hasło',
    confirmPassword: 'Potwierdź hasło',
    accept: 'Zaakceptuj',
    termsAndConditions: 'regulamin',
    samePasswordsError: 'Hasła muszą być identyczne!',
    confirmViaEmail: 'Potwierdź dostęp przez maila.',
    confirmViaEmailCloseTab: 'Potwierdź dostęp przez maila. \n Nie zamykaj tej karty',
    checkSpam: 'Sprawdź czy nie trafił do spamu',
    enterEmail: 'Podaj adres email',
    invalidEmail: 'Podaj poprawny adres email',
    validating: 'Potwierdzanie...',
    closeThisWindow: 'Możesz zamknąć to okno',
    invitationExpired: 'Zaproszenie wygasło',
    phoneNumber: 'Numer telefonu',
    forSmsNotifications: 'Opcjonalne, do powiadomień sms',
  },
  paymentStatus: {
    copyLink: 'Kopiuj link do naliczeń',
    generateLink: 'Generuj link do opłacenia naliczeń',
    unableToGetData: `Nie udało się pobrać danych. Link jest nieprawidłowy lub stracił ważność. 
      Zaloguj się aby sprawdzić naliczenia`,
    payAll: 'Opłać wszystkie',
    report: 'Zgłoś błąd',
    reportText: `Poniższe naliczenia mogą się różnić od tych z powiadomienia
    jeśli organizacja w międzyczasie wprowadziła zmiany lub zarejestrowała wpłatę.
    Przelewy i wpłaty gotówkowe są często dodawane do Pagaspot z opóźnieniem, co
    również wpływa na aktualny stan naliczeń. Jeśli widzisz błąd, poinformuj o nim organizację`,
    goBack: 'Wróć',
    reportTitle: 'Zgłoś nieprawidłowe naliczenie',
    selectAccountError: 'Nie można wybrać naleności z różnych kont bankowych',
    messageSent: `<b>Wiadomość wysłana!</b><br> Zaloguj się jeśli chcesz zobaczyć wysłane zgłoszenia. 
      Organizacja wprowadzi korekty lub skontaktuje się z tobą w celu wyjaśnienia rozliczeń.`,
    linkCopied: 'Skopiowano!',
    clickToCopy: 'Kliknij aby skopiować',
  },
  terms: {
    title: 'Regulamin',
    comingSoon: 'Już wkrótce',
  },
  general: {
    today: 'Dzisiaj',
    name: 'Nazwa',
    code: 'Kod',
    title: 'Tytuł',
    send: 'Wyślij',
    save: 'Zapisz',
    saved: 'Zapisano!',
    add: 'Dodaj',
    edit: 'Edytuj',
    manage: 'Zarządzaj',
    verify: 'Zweryfikuj',
    sent: 'Wysłano',
    cancel: 'Anuluj',
    remove: 'Usuń',
    showMore: 'Pokaż więcej',
    confirmRemove: 'Potwierdź usunięcie',
    search: 'Szukaj',
    close: 'Zamknij',
    open: 'Otwórz',
    value: 'Wartość',
    from: 'Od',
    to: 'Do',
    export: 'Eksportuj',
    select: 'Wybierz',
    next: 'Dalej',
    back: 'Wróć',
    undo: 'Cofnij',
    description: 'Opis',
    change: 'Zmień',
    confirm: 'Potwierdź',
    signature: 'Pieczęć i podpis',
    sortBy: 'Sortuj według',
    groupBy: 'Grupuj',
    default: 'Domyślny',
    selectAll: 'Wybierz wszystkich',
    comingSoon: 'Dostępne wkrótce',
    skip: 'Pomiń',
    archive: 'Archiwizuj',
    restore: 'Przywróć',
    dataFreshness: 'Aktualność danych',
    yes: 'Tak',
    no: 'Nie',
    advanced: 'Zaawansowane',
    operationTypes: 'Rodzaje zdarzeń',
  },
  menu: {
    clientView: 'Widok płatnika',
    home: 'Pulpit',
    me: 'Ja',
    payers: 'Płatnicy',
    bills: 'Naliczenia',
    billingPlans: 'Plany naliczeń',
    payments: 'Wpłaty',
    reports: 'Raporty',
    messages: 'Wiadomości',
    shop: 'Sklep',
    settings: 'Ustawienia',
    logout: 'Wyloguj',
    enrollement: 'Zgłoszenia',
    accounting: 'Dokumenty księgowe',
    documents: 'Dokumenty',
    companies: 'Organizacje',
    userSettings: 'Ustawienia użytkownika',
    import: 'Import',
    notifications: 'Powiadomienia',
    changePassword: 'Zmień hasło',
    version: 'wersja',
    tooltip: {
      liabilities: `Wyświetl kwotę zaległości, oczekujących należności
      czy nadpłaty przez najechanie myszką na kolumnę należności.<br>
      <i class="fas fa-exclamation-circle text-danger" ></i> - płatnik nie zapłacił należności w terminie <br>
      <i class="far fa-clock text-secondary" ></i> -
       płatnik ma naliczoną należność, której termin jeszcze nie upłynął<br>
      <i class="fas fa-hand-holding-usd text-success" ></i> - płatnik posiada nadpłatę`,
      addClient: `Skorzystaj z tej funkcji dodając pojedynczego płatnika.
      Pamiętaj, że możesz zaimportować dane wielu płatników z pliku excel wchodząc w
       Ustawienia -> zakładka Płatnicy -> przycisk “importuj płatników"`,
      checkbox: `Wybierz płatników, którym chcesz: <br>
      - wysłać wiadomość w Pagaspot <br>
      - naliczyć należność<br>
      - dodać do utworzonych grup<br>
      - usunąć<br>
      - wysłać aktywację - zaproszenie do założenia konta w Pagaspot`,
      clientActivation: `<i class="fas fa-check-circle text-success" > </i> -
      płatnik się zarejestrował oraz ma dostęp do rozliczeń <br>
      <i class="fas fa-paper-plane text-primary" > </i>  - do płatnika zostało wysłane zaproszenie do założenia konta`,
    },
  },
  shop: {
    layout: 'Widok',
    products: 'Produkty',
    product: 'Produkt',
    diet: 'Dieta',
    payers: 'płatników',
    productSets: 'Zestawy',
    productSet: 'Zestaw',
    isPaid: 'Opłacony?',
    created: 'Data zamówienia',
    productCategory: 'Kategoria produktów',
    updateOrder: 'Zapisać zmienioną kolejność?',
    assignments: 'Przypisania',
    addToBasket: 'Dodaj do koszyka',
    removeFromBasket: 'Usuń z koszyka',
    discount: 'Rabat',
    orderForDay: 'Zamówienia na dzień',
    noGroup: 'Bez grupowania',
    orderedDate: 'Zamówiono',
    orderChangeSaved: 'Zapisano zmienioną kolejność!',
    orderStatus: 'Status zamówienia',
    status: {
      all: 'Wszystkie',
      name: 'Nazwa statusu',
    },
    category: {
      catering: 'Catering',
      courses: 'Zajęcia',
      events: 'Wydarzenia',
      trips: 'Wycieczki',
      items: 'Przedmioty',
    },
    listing: {
      addSameAsListing: 'Dodaj taki jak oferta',
      limitCount: 'Ogranicz ilość',
      count: 'Ilość',
      price: 'Cena',
      browse: 'Wybierz zdjęcie',
      browsePlaceholder: '-',
    },
    timeReference: {
      timeReference: 'Odnieś do',
      dayOffset: 'Przesunięcie dni',
      daysBefore: 'dni wcześniej',
      daysAfter: 'dni później',
      listingMonthStart: 'Początek miesiąca',
      listingMonthEnd: 'Koniec miesiąca',
      listingDay: 'Dzień zamówienia',
    },
    catalog: {
      catalogs: 'Katalogi produktów',
      add: 'Dodaj katalog',
      edit: 'Edytuj katalog',
      catalog: 'Katalog',
      name: 'Nazwa katalogu',
      settings: 'Domyślny katalog produktów',
      orderSchema: 'Sposób zamawiania',
      type: {
        single: 'Zamówienie jednorazowe',
        singleDescription: 'Produkty z oferty będą dostępne do zamówienia bez kontekstu czasowego',
        daily: 'Zamówienia dzienne',
        dailyDescription: 'Produkty z oferty będą dostępne do zamówienia na konkretny dzień',
        monthly: 'Zamówienia miesięczne',
        monthlyDescription: 'Produkty z oferty będą dostępne do zamówienia na konkretny miesiąc',
      },
    },
    offer: {
      offer: 'Oferta',
      aggregatedPrice: 'Cena',
      aggregatedCount: 'Ilość',
      add: 'Dodaj ofertę',
      upload: 'Wgraj ofertę',
      export: 'Pobierz ofertę do Excela',
      noMatchingOffer: 'Brak pasującej oferty',
      clickToOpenListing: 'Kliknij aby pokazać ofertę',
    },
    subcatalog: {
      add: 'Dodaj podkatalog',
      edit: 'Edytuj podkatalog',
      name: 'Nazwa podkatalogu',
      removeWarning: 'Wszystkie pod-katalogi zostaną również usunięte!',
      displayLayout: 'Wyświetl przy zamawianiu jako',
      groupEditInSettings: 'Edycja grup dostępna tylko w ustawieniach',
      layout: {
        none: '-',
        primary: 'Pierwsze menu',
        secondary: 'Drugie menu',
      },
    },
    subcatalogNode: {
      add: 'Dodaj węzeł podkatalog',
      edit: 'Edytuj węzeł podkatalogu',
      parent: 'Węzeł nadrzędny',
      name: 'Nazwa węzła podkatalogu',
      removeWarning: 'Wszystkie pod-katalogi zostaną również usunięte!',
    },
    otherBills: 'Nieopłacone zamówienia z tego okresu',
    noOrdersForToday: 'Brak zestawów zamówionych na dzisiaj',
    noteToOrder: 'Komentarz do zamówienia',
    showProducts: 'Pokaż produkty',
    hideProducts: 'Ukryj produkty',
    addAvailability: 'Zaplanuj dostępność',
    addMissingProducts: 'Automatycznie utwórz brakujące produkty',
    plan: 'Harmonogram',
    price: 'Cena',
    showUnpaid: 'Pokaż nieopłacone',
    available: 'Dostępnych do zamówienia',
    ordered: 'Zamówionych',
    unpaid: 'Nieopłaconych',
    calendar: 'Kalendarz',
    list: 'Lista',
    orderSuccessful: 'Zamówione!',
    availabilityRule: 'Ogarnicz dostęp regułą',
    comment: 'Komentarz',
    screenForDelivery: 'Ekran do wydawania',
    timeToPay: 'Czas na opłacenie zamówienia',
    cancelAutomatically: 'Anuluj nieopłacone zamówienie',
    cancelAutomaticallyInfo: 'Gdy minie termin zapłaty, zamówienie zostanie automatycznie anulowane. Użytkownik zostanie poinformowany wiadomością email.',
    delivered: 'Wydano',
    delivery: {
      first: 'Pierwsze wydanie',
      next: '{0} wydanie!',
    },
    deliveryPlan: {
      menu: 'Jadłospisy',
      add: 'Dodaj jadłospis',
      selectCategoriesForMenu: 'Wybierz kategorie dla menu',
    },
    reports: {
      send: 'Wysyłka raportów',
      sendReport: 'Wyślij raport',
      paid: 'Opłacone',
      forDay: 'Z dnia',
      forDayInfo: `'0' oznacza raport na dzisiaj.
Wartość ujemna oznacza raport za dzień wcześniejszy (np. -1 to raport za wczoraj).
Z kolei wartości dodatnie oznaczają raport na nadchodzący dzień (np. 1 to raport na jutro).
Jeśli wybrano 'Pomiń weekend', dla wartości -1 w poniedziałek zostaną wysłane dane z piątku, lub odwrotnie dla +1.`,
      unpaid: 'Nieopłacone',
      selectReportType: 'Wybierz typ raportu',
      filterGroups: 'Filtruj grupy',
      includeProperties: 'Dołącz pola',
      emails: 'Adresy email',
      lineSeparated: 'w kolejnych liniach',
      emailsInLines: 'email 1\nemail 2\n...',
      hour: 'Godzina',
      sort: 'Sortuj według: (określ kolejność)',
      includedProperties: 'Dołączone pola (kolejność określa sortowanie)',
      availableProperties: 'Dostępne pola',
      name: 'Nazwa raportu (widoczna w tytule maila)',
      type: {
        dailyOrders: 'Szczegółowy raport zamówień dla każdego płatnika',
        dailyProductSetSummary: 'Raport z podsumowaniem ilości zamówionych zestawów',
      },
    },
    form: {
      selectPayer: 'Wybierz płatnika',
      selectDays: 'Wybierz dni',
      selectProductSet: 'Wybierz zestaw',
    },
    settings: {
      module: 'Moduł sklepu',
      enabledInfo: 'Włącza moduł sklepowy',
      orderRules: 'Reguły zamawiania',
      allowMultipleSets: 'Zamawianie wielu takich samych zestawów',
      allowMultipleSetsInfo: 'Pozwól na zamówienie wielokrotnie tego samego zestawu jednego dnia',
      productSetTypes: 'Typy zestawów',
      productSetType: 'Typ zestawu',
      addType: 'Dodaj typ zestawu',
      editType: 'Edytuj typ zestawu',
      typeName: 'Nazwa typu zestawu',
    },
    downloadReport: 'Pobierz raport',
    confirmOrder: 'Potwierdź',
    toBeCanceled: 'Odwołanie',
    orderCreated: 'Twoje zamówienie zostało przyjęte',
    productSetRemoveWarning: `
      Cały kalendarz dostępności tego zestawu zostanie usunięty! 
      Historyczne zamówienia tego zestawu bedą wciąż dostępne`,
    import: 'Importuj produkty i harmonogram',
    confirmImport: 'Potwierdź import',
    continueWithErrors: 'Ignoruj błędy i kontynuuj',
    unableToCreateOrder: 'Wystąpił błąd, zamówienie nie zostało utworzone. Skontaktuj się z nami: pomoc@pagaspot.com',
    thisIsShop: `
      W tym miejscu pojawią się produkty i usługi oferowane przez 
      organizacje takie jak obiady, zapisy na obozy, bluzy klubowe itd.<br><br>
      Jeśli nie widzisz w tym miejscu np. oferty obiadowej, to znaczy że organizacja nie włączyła modułu sklepu`,
    thisWillBeAShop: `W tym miejscu będziesz mógł udostępnić płatnikom produkty i usługi 
      takie jak zapisy na obozy, szkolne obiady, bluzy klubowe itd.<br><br>
      Na razie udostępniliśmy ten moduł tylko organizacjom biorących udział w testach, jeśli jesteś zainteresowany, napisz na <b>pomoc@pagaspot.com</b>`,
    mealOffer: 'Oferta obiadowa',
    shopPayerHelp: `
      Aby zamówić posiłki, wybierz odpowiednie dni w kalendarzu.<br>
      <b>Aby zaznaczyć wiele dni - naciśnij, przytrzymaj i przesuń kursor/palec nad nimi</b><br><br>
      Aby zobaczyć szczegóły posiłków, wybierz nie więcej niż 1 dzień.<br><br>
      Możesz dodawać i usuwać dowolne zestawy. Po przejściu do koszyka będziesz mógł potwierdzić zamówienie.`,
    availability: 'Dostępność',
    addPayersGroup: 'Dodaj grupę płatników',
    addProduct: 'Dodaj produkt',
    editProduct: 'Edytuj produkt',
    editAvailability: 'Zmień dostępność',
    addProductSet: 'Dodaj zestaw',
    newProduct: 'Nowy produkt',
    newProductVariant: 'Nowy wariant produktu',
    addOrders: 'Dodaj zamówienia',
    newProductSet: 'Nowy Zestaw',
    orderedSets: 'Zamówione zestawy',
    orderSet: 'Zamów zestaw',
    selectDays: 'Wybierz dni aby wyświetlić dostępne zestawy',
    orderTime: 'Godzina realizacji zamówienia',
    orderTo: 'Zamów do',
    orderToInfo: 'Do kiedy (lub jakiej godziny) płatnik może zamówić zaplanowane produkty',
    orderFrom: 'Zamów od',
    orderFromInfo: 'Od kiedy produkty będą widoczne dla płatników do zamówienia',
    timeBoundaries: 'Ograniczenia czasowe',
    orderFulfillmentBased: 'W dniu zamówienia',
    cancelTo: 'Odwołaj do',
    cancellationExpired: 'Termin odwołania minął',
    cancelToInfo: 'Do kiedy (lub jakiej godziny) płatnik może odwołać zaplanowany produkt',
    payTo: 'Opłać do',
    payToInfo: 'Czas na opłacenie zamówienia',
    requirePrepayment: 'Wymagaj opłacenia z góry',
    requirePrepaymentInfo: 'Zaraz po złożeniu zamówienia użytkownik zostanie przekierowany do bramki płatniczej',
    tagsAlergens: 'Alergeny',
    tags: 'Tagi',
    notPaid: 'Nieopłacony',
    notPaidPlural: 'Nieopłacone',
    offerNotAvailable: 'Oferta niedostępna',
    paid: 'Opłacony',
    paidPlural: 'Opłacone',
    tag: 'Tag',
    orders: 'Zamówienia',
    noOrders: 'Brak zamówień w tym okresie',
    cancellations: 'Odwołania',
    other: 'Inne',
    revalidate: 'Zweryfikuj ponownie',
    cancel: 'Odwołaj',
    order: 'Zamów',
    orderMore: 'Zamów więcej',
    orderAndPay: 'Zamów',
    orderError: {
      noPlan: 'Brak jadłospisu',
      noMenu: 'Brak jadłospisu dla:',
    },
    error: {
      UnableToParse: 'Plik nie mógł być przetworzony, upewnij się czy format jest prawidłowy',
      ProductDupliated: 'Produkt \'{0}\' pojawia się dwukrotnie. Nazwy produktów powinny być unikalne',
      InvalidTimeBoundary: 'Niewłaściwa definicja czasu \'{0}\'',
      NoPriceForProductSet: 'Zestaw \'{0}\' nie ma żadnej zdefiniowanej ceny',
      UnknownProductSet: 'Nieznany zestaw \'{0}\', zanim zaimportujesz harmonogram zestawów, musisz najpierw je stworzyć',
      UnrecognizedProduct: 'Nierozpoznany produkt \'{0}\', dodaj go ręcznie lub uzupełnij plik który importujesz',
      ProductNotUsed: 'Produkt \'{0}\' zostanie dodany, mimo że nie został użyty w harmonogramie',
      SetsInScheduleMissingInAvailabilities: 'Zestawy \'{0}\' występują w zakładce "Harmonogram", ale nie ma ich w zakładce "Zestawy"',
      SetsInAvailabilitiesMissingInSchedule: 'Zestawy \'{0}\' występują w zakładce "Zestawy", ale nie ma ich w zakładce "Harmonogram"',
      IncompleteAvailabilityDefinition: `
        Zestawy dla których jest zdefiniowany harmonogram nie zgadzają się z zakładką "Zestawy" 
        Upewnij się że nazwy zestawów w zakładce "Harmonogram" i "Zestawy" się zgadzają`,
      UnableToSave: 'Nie udało się zapisać danych',
      InvalidGroupName: 'Nierozpoznana grupa płatników: \'{0}\'',
      InvalidPrice: 'Nieprawidłowa cena \'{0}\'',
      InvalidDate: 'Nieprawidłowa data \'{0}\'',
      InvalidDiet: 'Nieprawidłowa dieta \'{0}\'',
      InvalidSubCatalog: 'Podkatalog \'{0}\' nie istnieje',
      InvalidSubCatalogNode: 'Kategoria w podkatalogu \'{0}\' nie istnieje',
      InvalidProduct: 'Produkt \'{0}\' nie istnieje',
      UnknownColumn: 'Nierozpoznana kolumna \'{0}\'',
    },
  },
  accounting: {
    noDocuments: 'Brak dokumentów',
    corrections: 'Korekty',
    selected: 'Wybrane',
    billingNotes: 'Noty księgowe',
    preview: 'Podgląd',
    dueBefore: 'Naliczono przed',
    dueAfter: 'Naliczono po',
    download: 'Pobierz',
    getPdf: 'Pobierz PDF',
    previewNotAvailable: 'Podgląd niedostępny. Spróbuj pobrać PDF',
    scheduleSend: 'Zaplanuj wysyłkę wiadomości email zawierającej noty',
    generateBillingNotes: 'Dodaj noty księgowe',
    approve: 'Zatwierdź',
    settings: {
      columnTitle: 'Tytuł kolumny',
      module: 'Moduł dokumentów księgowych',
      enabledInfo: 'Włącza funkcjonalności dotyczące wystawiania dokumentów księgowych',
    },
  },
  report: {
    report: 'Raport',
    balanceReport: 'Raport salda',
    settlementReport: 'Raport rozliczeń',
    customRange: 'Wybierz zakres',
    billsFromTheScope: 'Należności z tego okresu',
    paidFromTheScope: 'Rozliczenie należności z tego okresu',
    notPaidFromTheScope: 'Nieopłacone należności z tego okresu',
    paymentsFromTheScope: 'Wpłaty z tego okresu',
    config: {
      title: 'Konfiguracje raportu',
      add: 'Dodaj konfigurację',
      name: 'Nazwa konfiguracji',
    },
    time: {
      currentMonth: 'Ten miesiąc',
      previousMonth: 'Poprzedni miesiąc',
      lastThreeMonths: 'Ostatnie 3 miesiące',
      thisYear: 'Od początku roku',
      skipWeekend: 'Pomiń weekend',
    },
    balance: {
      balanceBefore: 'Saldo przed',
      balanceAfter: 'Saldo po',
      due: 'Naliczenie',
      paid: 'Wpłata',
      refund: 'Zwrot',
      refunds: 'Zwroty',
      settled: 'Rozliczono',
      payerProperties: 'Dane płatnika',
      splitByCategory: 'Podziel na kategorie',
      splitByBillingScope: 'Podziel na okres rozliczeniowy',
    },
  },
  userSettings: {
    newPassword: 'Nowe hasło',
    changePassword: 'Zmień hasło',
    currentPassword: 'Aktualne hasło',
    passwordsMustBeIdentical: 'Hasła muszą być identyczne',
    tooShortPassword: 'Za krótkie hasło (min. 5 znaków)',
  },
  groups: {
    groups: 'Grupy',
    group: 'Grupa',
    newGroup: 'Nowa grupa',
    edit: 'Edytuj grupę',
    add: 'Dodaj grupę',
    select: 'Wybierz grupę płatników',
    everyoneInGroup: 'Wszyscy z grupy',
    selectAll: 'Wybierz wszystkie',
    controlWithRule: 'Przypisz płatników regułą',
    controlledWithRule: 'Przypisanie kontrolowane regułą',
    rule: 'Reguła przypisania',
  },
  payers: {
    add: 'Dodaj płatnika',
    archived: 'Zarchiwizowany',
    archivedPayers: 'Zarchiwizowani płatnicy',
    seeBefore: 'Zobacz zarchiwizowanych przed {0}',
    noArchived: 'Brak zarchiwizowanych płatników',
    balance: 'Saldo',
    activation: 'Aktywacja',
    invite: 'Zaproś',
    noEmails: 'Brak zdefiniowanych adresów email',
    payers: 'płatników | płatnik | płatników',
  },
  company: {
    theCompany: 'Organizacja',
    companyCreated: 'została utworzona',
    clientFields: 'Dodatkowe pola',
    companies: 'Moje organizacje',
    all: 'Wszyscy',
    noPayers: 'Brak płatników',
    selectPayers: 'Wybierz płatników',
    allClientsSelected: 'Wszyscy płatnicy wybrani',
    newClient: 'Nowy płatnik',
    connectedUsers: 'Podłączeni użytkownicy',
    companyInfo: 'Dane organizacji',
    logo: 'Logo organizacji',
    contactInfo: 'Dane kontaktowe',
    contactEmail: 'Email do kontaktu',
    contactPhone: 'Telefon do kontaktu',
    contactInfoNotify: `Dane kontaktowe pojawią się w stopce wszystkich powiadomień wysyłanych przez Pagaspot do twoich płatników.
W celu edycji podstawowych danych takich jak nazwa organizacji skontaktuj się z nami: pomoc@pagaspot.com`,
    giveOtherInvoiceInfo: 'Podaj inne dane organizacji do faktury',
    otherInvoiceInfo: 'Inne dane organizacji do faktury',
    nameTaken: 'Nazwa organizacji "{name}" jest zajęta. Proszę wprowadź inną nazwę.',
    newCompanyRegister: 'Formularz rejestracji nowej organizacji',
    addNewCompany: 'Dodaj nową organizację',
    accountType: {
      accountType: 'Typ konta',
      license: 'Licencja',
      demo: 'DEMO',
    },
    useDemoAccount: 'Uwaga, używasz konta DEMO.',
    activateAccount: '(Kliknij aby aktywować)',
    logoutToAccess: 'Aby mieć dostęp do utworzonej organizacji musisz zalogować się ponownie.',
    stayLoggedIn: 'Pozostań zalogowany',
    logOutNow: 'Wyloguj teraz',
    activateCompany: 'Aktywuj organizację',
    chooseAccountType: 'Wybierz typ konta, aby aktywować organizację',
    accountTypes: {
      fullLicense: 'Pełna licencja',
      nonProfit: 'Non-profit',
      enterprise: 'Enterprise',
    },
    sendForVerification: 'Wyślij do weryfikacji',
    isBeingVerified: 'Trwa weryfikacja Twojego zgłoszenia, do tego czasu możesz korzystać z konta DEMO.',
    confirmActivate: 'Potwierdź prośbę o aktywację konta',
    confirmToUnlock: `Obecnie używasz konta DEMO. 
      Potwierdź prośbę o aktywację, aby odblokować pełne możliwości Twojego konta.`,
  },
  demoAccount: {
    notAvailable: 'Niedostępne w wersji DEMO',
    clientsLimit: 'Limit użytkowników w wersji DEMO: 500',
  },
  form: {
    clearAll: 'Wyczyść',
    selected: 'Wybrani',
    overwriteAmount: 'Ustaw kwotę {0} {1} wszystkim',
    selectPayers: 'Wybierz płatników',
    selectAmount: 'Ustal kwotę',
    addPayers: 'Dodaj płatników',
    titleAndDescription: 'Tytuł i opis',
    summary: 'Podsumowanie',
    weSavedYourWork: 'Zapisaliśmy twoją pracę',
    restore: 'Przywróć',
    decisionMaker: 'Jestem osobą decyzyjną.',
    acceptTerms: `Wyrażam zgodę na przetwarzanie danych osobowch. 
      Administrartorem Twoich danych osobowych jest Pagaspot Sp. z o.o., 
      NIP: 5223176796, ul. Wyrzyska 16 A, 02-455 Warszawa.
      Twoje dane będą przetwarzanie w celu prowadzenia konta w Pagaspot.`,
    iAccept: 'Akceptuję',
    termsCommercialUsers: 'regulamin dla użytkowników komercyjnych.',
    mandatoryAgreements: 'Obowiązkowe zgody',
    defaultAccountType: `Podstawowym typem konta jest konto DEMO. 
      Możesz rozpocząć proces weryfikacji teraz lub później w ustawieniach.`,
    street: 'Ulica',
    postCode: 'Kod pocztowy',
    city: 'Miasto',
  },
  client: {
    me: 'Ja',
    active: 'Aktywny',
    basket: 'Koszyk',
    position: 'Stanowisko',
    number: 'Numer płatnika',
    connected: 'Podłączony_a',
    pendingInvitation: 'Oczekujące zaproszenie',
    pendingInvitationInfo: 'Po zaakceptowaniu zaproszeń będziesz mógł przejrzeć i opłacić rozliczenia tych płatników oraz złożyć zamówienie',
    clientCode: 'Kod płatnika',
    noConnectedClients: 'Brak podłączonych płatników!',
    noConnectedClientsInfo: `
      <b>MODUŁ ROZLICZENIOWY:</b><br>
      - Upewnij się że zalogowałeś się na email który został podany organizacji.<br>
      <br>
      <b>MODUŁ CATERINGOWY:</b><br>
      - Upewnij się, że skorzystałeś z przesłanego przez placówkę linku do rejestracji<br>
      - Upewnij się, że zalogowałeś się na email, który został wpisany w formularzu<br>
      <br>
      Jeśli dalej nie widzisz tu rozliczeń, skontaktuj się z cateringiem/szkołą/klubem który wysłał ci zaproszenie<br>
      <br>
      Jeśli sam chciałbyś prowadzić rozliczenia swojej organizacji w Pagaspot, skontaktuj się z nami na pomoc@pagaspot.com`,
    noConnectedClientsShopInfo: 'Wróć do zakładki Pulpit',
    activation: 'Aktywacja',
    excessFor: 'nadpłata na',
    receiver: 'Odbiorca',
    bills: 'Do zapłaty',
    paid: 'Opłacone',
    paymentSettledInBackground: 'Płatność zostanie przetworzona w tle',
    overLimit: 'Kwota jednej płatności nie może przekraczać <b>{n} zł</b>',
    internalPaymentInfo: 'Do transakcji doliczymy <b>{n} zł</b> prowizji za realizacje płatności.',
    commission: 'Prowizja za realizację płatności',
    seePaid: 'Zobacz opłacone',
    seeUnpaid: 'Zobacz nieopłacone',
    groupsInfo: `Wybrane opłaty muszą trafić na różne konta bankowe, kliknij "Zapłać" dla każdej grupy
     lub wykonaj tradycyjny przelew według wskazówek niżej`,
    gatewayDisabled: 'Naliczający nie włączył bramki płatniczej. Aby uregulować należność wykonaj przelew:',
    pay: 'Zapłać',
    payAll: 'Zapłać wszystko',
    creditReturn: 'Zwrot środków z',
    paymentRegistered: 'Płatność zarejestrowana',
    disconnectAccount: 'Odłącz konto płatnika',
    disconnect: 'Odłącz',
    import: 'Importuj płatników',
    pendingInvitations: 'Oczekujące zaproszenia',
    late: 'Zalegający',
    notLate: 'Nie zalegający',
    clickToPay: 'Kliknij tu aby zapłacić',
    clickToConfirm: 'Kliknij tu aby potwierdzić',
    alreadySettled: 'Rozliczono już <b>{settled}</b> z <b>{value}</b> z tej wpłaty',
    payment: {
      toPay: 'Do zapłaty',
      paid: 'Zapłacono',
    },
    settings: {
      settings: 'Ustawienia',
      notifications: 'Powiadomienia',
      connectedClients: 'Podłączeni płatnicy',
      notifyNewPayments: 'Powiadom o nowych należnościach i zmianach',
      remindersInfo: `Przypomnienia o niezapłaconych należnościach wysyłamy w imieniu organizacji. 
        Jeśli nie chcesz ich otrzymywać, skontaktuj się z organizacją która je wysyła`,
    },
    name: 'Nazwisko i Imię',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    email: 'Mail',
    activate: 'Aktywuj',
    sendInvite: 'Wyślij zaproszenie',
    inviteSent: 'Zaproszenie wysłane',
    activated: 'Aktywowani',
    notInvited: 'Nie zaproszeni',
    sendAgain: 'Wyślij ponownie',
    sendAgainIfNoResponse: 'Wyślij ponownie do nieaktywowanych płatników',
    shop: {
      ordered: 'Zamówiono',
      cancelled: 'Odwołano',
      fromOverpayment: 'Z nadpłaty',
    },
  },
  activation: {
    activation: 'Aktywacja kont płatników',
    send: 'Wyślij zaproszenia',
    sendReminders: 'Przypomnij tym którzy byli już zaproszeni, ale wciąż nie niezaakceptowali',
    clientActivation: 'Aktywacja konta {0}',
    invitationAlreadySent: 'Zaproszenie zostało już wysłane',
    willBeActivated: 'Płatnicy którzy zostaną aktywowani, zaproszenie zostanie wysłane na podane adresy email:',
    noEmail: `Płatnicy którzy nie zostaną aktywowani 
      bo nie mają aktywacyjnego adresu email. Uzupełnij go przed aktywacją:`,
  },
  transactions: 'Transakcje',
  transaction: {
    ignore: 'Pomiń',
    removeAndIgnore: 'Usuń i pomiń',
    settle: 'Rozlicz',
    transferSettlementInvalid: 'Przelew niewłaściwie rozliczony',
    addTransaction: 'Dodaj transakcję',
    amount: 'Kwota transakcji',
    no: 'Brak transakcji',
    value: 'Wartość transakcji',
    add: 'Dodaj wpłatę',
    import: 'Importuj wyciąg bankowy',
    remove: 'Wprowadź korektę',
    selectClient: 'Wybierz płatnika',
    provideTransactionValue: 'Podaj wartość transakcji',
    fromExcessPayment: 'Z nadpłaty',
  },
  bankStatements: {
    title: 'Wyciągi bankowe',
    bankStatement: 'Wyciąg',
    transferStatus: 'Stan rozliczenia',
    suggestSettlement: 'Podpowiedz rozliczenie',
    noStatements: 'Brak wyciągów z {0} roku',
    transferDetails: 'Szczegóły przelewu',
    duplicateWarning: 'Ten przelew jest duplikatem przelewu z innego wyciągu, aby zmienić jego rozliczenie zmień obserwowany wyciąg',
    transfer: {
      skip: 'Pomiń w rozliczeniach',
      settle: 'Rozlicz',
      startSettle: 'Zacznij rozliczanie',
    },
    statuses: {
      unsettled: 'Nierozliczone',
      settled: 'Rozliczone',
      skipped: 'Pominięte',
      single: {
        settled: 'Rozliczony',
        skipped: 'Pominięty',
      },
    },
  },
  billingScopes: {
    billingScopes: 'Okresy rozliczeniowe',
  },
  bill: {
    settled: 'Zaksięgowano',
    code: 'Kod księgowy',
    none: 'Brak naliczeń',
    shopBill: 'Naliczenie wynikające z zamówień ze sklepu',
    cannotDeleteBill: `Nie można usunąć należności gdy filtrujesz płatników.
      Jeśli chcesz usunąć płatników z tego naliczenia - wybierz 'Zmień kwoty'.
      Aby usunąć  naliczenie dla wszystkich podpiętych osób - wyczyść filtr na górze strony`,
    noneToPay: 'Wszystkie należności opłacone',
    edit: 'Edytuj naliczenie',
    add: 'Dodaj naliczenie',
    import: 'Importuj należności i wpłaty',
    showFuture: 'Pokaż nadchodzące okresy rozliczeniowe',
    toPay: 'Do zapłaty',
    invalidCategoryAccountNumber: `Uwaga! 
      Ta kategoria jest przypisana do bramki która rozlicza wpłaty na inny numer konta: {0},
      niż ten wybrany w poprzednim kroku`,
    editDetails: 'Edytuj szczegóły',
    category: 'Kategoria należności',
    amount: 'Kwota należności',
    editPayers: 'Zmień kwoty',
    allPaymentsMovedToExcess: `Wszystkie wpłaty na tą należność zostaną przeniesione na nadpłatę 
    i automatycznie rozliczone na zalegające należności`,
    closeScopeInfo: `Po zamknięciu okresu rozliczeniowego, 
      dodawanie wpłat i modyfikacja naliczeń w tym okresie zostanie zablokowana`,
    closeScope: 'Zamknij okres',
    openScopeInfo: `Po otworzeniu okresu rozliczeniowego, dodawanie wpłat i 
      zmiana naliczeń będzie ponownie możliwa`,
    openScope: 'Otwórz okres',
    plans: 'Plany naliczeń',
    changeHistory: 'Historia zmian',
    notAssigned: 'Płatnik nie jest przypisany do tej należności',
    resettlementNeeded: 'Wpłaty {0} płatników przekraczają kwotę należności',
    resettleForCategory: 'Wybierz kategorię na którą zostaną przeksięgowane wpłaty',
    audit: {
      BillPayerAddedEvent: 'Płatnik dodany',
      BillPayerRemovedEvent: 'Płatnik usunięty',
      BillPayerUpdatedEvent: 'Naliczenie zmienione',
      MoneyAddedToBillEvent: 'Rozliczono wpłatę',
      MoneyRemovedFromBillEvent: 'Wycofano wpłatę',
    },
    error: {
      UnknownPayer: 'Nieznany płatnik \'{0}\'',
      DuplicatedPayer: 'Płatnik \'{0}\' pojawia się wielokrotnie w pliku',
      MultipleMatchingPayers: 'Płatnik \'{0}\' nie może być jednoznacznie zidentyfikowany',
    },
  },
  billingPlan: {
    add: 'Dodaj plan naliczeń',
    to: 'Koniec naliczania',
    plan: 'Plan naliczeń',
    select: {
      all: 'Wszystkie',
      enabled: 'Aktywne',
      disabled: 'Wyłączone',
    },
    generateDaysBefore: 'Wygeneruj naliczenie przed terminem (dni)',
    none: 'Brak zdefiniowanych planów naliczeń',
    activate: 'Aktywuj plan',
    enabled: 'Aktywny',
    disabled: 'Wyłączony',
  },
  payment: {
    none: 'Brak wpłat',
    due: 'Naliczenie',
    add: 'Dodaj wpłatę',
    code: 'Kod naliczenia',
    overpayment: 'Nadpłata',
    toSettle: 'Do rozliczenia',
    details: 'Szczegóły',
    print: 'Wydrukuj',
    editSettlement: 'Edytuj rozliczenie',
    settlement: 'Rozliczenie',
    confirmation: 'Potwierdzenie wpłaty',
    for: 'Wpłata za',
    subject: 'Na rzecz',
    date: 'Data wpłaty',
    number: 'Numer',
    settled: 'Rozliczona',
    unsettled: 'Nierozliczona',
    addUnsettled: 'Dodaj nierozliczoną',
    payment: 'Płatność',
    creditReturn: 'Zwrot środków',
    donation: 'Darowizna',
    otherAmount: 'inna kwota',
    amountAndMethod: 'Kwota i metoda płatności',
    show: 'Pokaż należność',
    settledFor: 'Rozliczone na',
    title: 'Tytuł płatności',
    description: 'Opis',
    amount: 'Kwota',
    remaining: 'Pozostało',
    recurring: 'Cykliczna',
    single: 'Pojedyncza',
    eachPayer: 'Dla każdego płatnika z grupy',
    generate: {
      generate: 'Wygeneruj',
      lastGen: 'Ostatnio wygenerowano',
      selectDates: 'Wybierz daty',
      willBeGenerated: 'Zostaną wygenerowane',
    },
    audit: {
      audit: 'Audyt wpłat',
      changesFrom: 'Operacje z okresu',
    },
    autoPayment: 'Przypisz automatycznie',
    selectPayment: 'Wybierz płatność',
    editPayment: 'Edytuj wpłatę',
    repeatEvery: 'Powtarzaj co',
    from: 'Wyświetl od',
    to: 'Termin płatności',
    startDate: 'Data naliczenia',
    repeatFrom: 'Zacznij naliczanie',
    repeatTo: 'Zakończ naliczanie',
    daysToPay: 'Dni na zapłatę',
    templates: 'Szablony',
    addTemplate: 'Dodaj szablon',
    sum: 'Suma',
    paid: 'Zapłacono',
    paidOnTime: 'Zapłacono w terminie',
    paidLate: 'Zapłacono po terminie',
    notPaid: 'Nie zapłacono',
    warningNoBankAccount: 'Definicja konta bankowego "{0}" dla tego płatnika jest niewłaściwa',
    toPay: 'Do zapłaty',
    settleForUnPaid: 'Rozlicz <b>{0}</b> na niezapłacone',
    noSettleWarning: 'Naliczona kwota zostanie zapomniana!',
    addClient: 'Dodaj płatników',
    addDeleteClients: 'Dodaj / Usuń płatników',
    addClients: 'Dodaj do należności',
    addToTemplate: 'Dodać płatników też do szablonu',
    addParam: 'Dodaj pole',
    waiting: 'Oczekujące',
    additional: 'Dodatkowe dane',
    paymentHistory: 'Historia płatności',
    clientDeclaredPayment: 'Płatnik zadeklarował płatność',
    editRecurring: 'Edytuj szablony płatności cyklicznych',
    noRecurringPayments: 'Brak zdefiniowanych szablonów płatności cyklicznych',
    isAccountNumber: 'Czy jest to numer konta bankowego?',
    accountNumber: 'Numer konta',
    toStart: 'do początku',
    toEnd: 'do końca',
    toDeadline: 'do terminu',
    afterDeadline: 'po terminie',
    addPayment: 'Dodaj wpłatę',
    savePayment: 'Zapisz wpłatę',
    notify: 'Powiadom o naliczeniu nowej należności',
    notification: 'Powiadomienie',
    individualAmount: 'Indywidualne kwoty',
    completeInfo: `
      Należność zostanie zakończona.\n
      Nie będzie się ona wyświetlać płatnikom, niezależnie od tego czy zapłacili czy nie.
    `,
    completedInfo: `<b>Wszyscy zapłacili!</b><br> 
      Oznacz płatność jako zakończoną, będziesz mógł ją znaleźć w "Historii należności"`,
    paymentMethod: 'Metoda płatności',
    method: {
      transfer: 'Przelew',
      card: 'Karta',
      cash: 'Gotówka',
      internal: 'Aplikacja',
    },
    tooltip: `Kliknij w wybraną należność. Po prawej stronie wyświetlą się szczegóły wybranej należności.<br>
    Klikając:<br>
    Edytuj należność - zmień jej nazwę, popraw lub dodaj opis, ustaw nowy kanał
    należności czy określ od kiedy chcesz by płatnik widział tę należność oraz do kiedy powinien zapłacić. <br>
    Eksportuj -  pobierz do excela informację o obecnym stanie opłacenia należności płatników<br>
    Dodaj płatnika - wyszukaj płatnika, ustal kwotę naliczenia oraz dodaj go do należności<br>
    Zmień naliczenia - zmień kwotę naliczenia lub usuń płatnika z należności`,
    tooltipTemplate: `Szablony to miejsce, w którym możesz dodać powtarzającą się należność oraz
     zmienić parametry już dodanych. Zmiana parametrów takich jak: dodanie/usunięcie nowych płatników,
     zmiana kwoty naliczenia poskutkuje dodaniem kolejnej należności zaktualizowanej o wprowadzone zmiany.`,
  },
  forms: {
    open: 'Otwórz',
    inactive: 'Formularz nieaktywny',
    copyLink: 'Kopiuj link',
    copySuccess: 'Skopiowano!',
    cloneSuccess: 'Sklonowano!',
    separator: 'Separator',
    clone: 'Klonuj',
    remove: 'Usuń akcję',
    selectBillingPlan: 'Wybierz plan naliczeń',
    enableActionRule: 'Włącz akcję regułą',
    noPayerCreateActionDefined: 'Akcja tworząca płatnika nie została zdefiniowana!',
    action: {
      select: 'Wybierz akcję...',
      PayerCreate: 'Dodaj płatnika',
      PayerCreateInfo: 'Utwórz nowego płatnika z następującymi danymi:',
      PayeeNotification: 'Wyślij powiadomienie',
      PayeeNotificationInfo: 'Wyślij powiadomienie ze wszystkimi danymi z tego formularza do:',
      CreateBill: 'Dodaj naliczenie',
      CreateBillInfo: 'Dodaj naliczenie dla nowego płatnika:',
      AddToBillingPlan: 'Dodaj do planu naliczeń',
      AddToBillingPlanInfo: 'Dodaj płatnika do istniejącego planu naliczeń:',
    },
    forms: {
      layout: 'Wygląd',
      mapping: 'Mapowanie',
      actions: 'Akcje',
      validationScript: 'Skrypt walidujący',
      validationMessage: 'Wiadomość błędnej walidacji',
      editField: 'Edytuj pole formularza',
      fieldType: 'Typ pola',
      fieldDescription: 'Opis pola',
      fieldCode: 'Kod pola',
      options: 'Opcje (w kolejnych liniach)',

      textinput: 'Pole tekstowe',
      multiselect: 'Wielokrotny wybór',
      textareainput: 'Długie pole tekstowe',
      checkbox: 'Checkbox',
      dropdown: 'Lista rozwijana',
      statictext: 'Tekst statyczny',
    },
  },
  settings: {
    payments: 'Wpłaty',
    addField: 'Dodaj pole',
    billsCategories: 'Kategorie należności',
    editField: 'Edytuj pole',
    testRuleRun: 'Wykonaj regułę testowo',
    accounting: {
      billingNoteColumns: 'Dane w notach księgowych',
      notSet: 'Nie ustawiono',
      exportRules: 'Reguły eksportu',
    },
    forms: {
      forms: 'Formularze',
      addForm: 'Dodaj formularz',
      updateForm: 'Edytuj formularz',
      active: 'Aktywny',
      formName: 'Nazwa formularza',
      actionType: 'Akcja',
      configure: 'Konfiguruj',
    },
    billingScope: 'Okres rozliczeniowy',
    saveBeforeLeavePage: 'Czy chcesz zapisać zmiany przed opuszczeniem karty?',
    enableInternalPayments: 'Włącz bramkę płatniczą',
    periodicPaymentTemplates: 'Szablony płatności cyklicznych',
    clientGroups: 'Grupy płatników',
    clientPropertyRemove: 'Wszystkie dane płatników z tego pola zostaną usunięte!',
    defaultPaymentMethod: 'Domyślna metoda płatności',
    settlements: 'Rozliczenia',
    administrators: 'Administratorzy',
    access: 'Dostępy',
    roles: {
      add: 'Dodaj dostęp',
      edit: 'Edytuj dostęp',
      types: {
        payeeAdmin: 'Administrator organizacji',
        payersView: 'Dostęp do płatników',
        shopOrdersView: 'Dostęp do zamówień',
      },
    },
    adminPermissions: 'Uprawnienia Administratorów',
    paymentIntegrations: 'Integracja płatności',
    fullCompanyName: 'Pełna nazwa organizacji',
    shortCompanyName: 'Skrócona nazwa organizacji',
    paymentGateway: 'Bramka płatnicza',
    paymentGateways: 'Bramki płatnicze',
    paymentCommission: 'Opłaty za transakcję',
    commission: {
      oneForThousand: '1zł / 1000zł',
      oneForThousandInfo: '1zł za każdy rozpoczęty 1000zł',
      companyPays: 'Opłaty pokrywane przez organizację',
    },
    gateway: {
      enabled: 'Aktywna',
      disabled: 'Wyłączona',
      isDefault: 'Domyślna',
      add: 'Dodaj bramkę',
      edit: 'Konfiguracja bramki',
      accountNumberProvidedInForm: 'Numer konta podany przy rejestracji',
      categoriesOverlap:
        `Wybrane kategorie są już przypisane do innej bramki!
        Odepnij je przed zapisaniem`,
    },
    NIP: 'NIP',
    PESEL: 'PESEL',
    vatNumber: 'Numer VAT (NIP)',
    emailAdress: 'Adres e-mail',
    country: 'Kraj',
    address: 'Adres',
    phoneNumber: 'Numer telefonu',
    bankAccountNumber: 'Numer konta bankowego',
    clientsProperties: 'Pola z danymi płatników',
    includeBankAccountNumber: 'Dołączaj numer konta do wszystkich przyszłych płatności',
    clientProp: {
      propertyName: 'Nazwa pola',
      activationEmail: 'Email do aktywcji konta',
      groupping: 'Typ grupy',
      code: 'Kod pola',
      allowEdit: 'Włącz edycję przez płatnika',
      editRule: 'Reguła',
    },
    group: {
      name: 'Nazwa grupy',
      code: 'Kod grupy',
      parent: 'Groupa nadrzędna',
    },
    transferPrefix: 'Szablon tytułu przelewu',
    notifications: 'Powiadomienia',
    remindLatePayments: 'Przypomnij o spóźnionych płatnościach',
    categories: {
      name: 'Nazwa kategorii',
      add: 'Dodaj kategorię należności',
      edit: 'Edytuj kategorię należności',
      default: 'Domyślna',
      active: 'Aktywna',
      errorDefault: 'Nie można usunąć domyślnego kanału',
    },
    notify: {
      reminders: 'Przypomnienia',
      smsNotifications: 'Powiadomienia SMS',
      uninvitedPayers: 'Niezaproszeni płatnicy',
      sendBillUpdates: 'Powiadamiaj o nowych i anulowanych należnościach',
      sendBillUpdatesToInactive: 'Powiadamiaj niezaproszonych płatników o nowych i anulowanych należnościach',
      billUpdates: 'Dodanie i anulowanie należności',
      sendReminders: 'Powiadamiaj niezaproszonych o niezapłaconych należnościach',
      sendNewMessages: 'Powiadamiaj niezaproszonych o nowych wiadomościach',
      emailToEnable: 'Napisz na pomoc@pagaspot.com aby rozszerzyć subskrybcję o wiadomości SMS',
      default: 'Dodatkowe przypomnienie jest wysyłane zawsze dzień przed terminem',
      sendRemindersBySms: 'Wysyłaj przypomnienia o niezapłaceniu przez SMS',
      smsCount: 'Liczba SMS do wykorzystania',
      startFrom: 'Zacznij od',
      byEmail: 'Za pomocą emaila',
      never: 'Nigdy',
      every3days: 'Co 3 dni',
      everyWeek: 'Co tydzień',
      everyTwoWeeks: 'Co dwa tygodnie',
      everyMonth: 'Co miesiąc',
      addSms: 'Doładuj SMS',
      billingScopeSummary: 'Podsumowanie okresu rozliczeniowego',
      sendBillDueDateExpired: 'Przypomij o niezapłaceniu gdy mija termin',
      sendBillingScopeSummary: 'Wyślij podsumowanie okresu rozliczeniowego',
    },
    clientField: {
      other: 'Inne',
      email: 'Adres email',
      phone: 'Numer telefonu',
      accountNumber: 'Numer konta',
    },
    clientCode: 'Wyświetl identyfikatory płatników',
    info: {
      defaultGateway: `Bramka oznaczona jako 'domyślna', będzie używana do wszystkich 
        kategorii które nie zostały bezpośrednio skonfigurowane`,
      internalPayments: `
        Włączając bramki płatnicze udostępnisz płatnikom możliwość szybkiej zapłaty!<br> 
        Wpłaty wykonane w ten sposób, zostaną automatycznie dodane i rozliczone.<br>
        Aby uruchomić bramkę procentową, skontaktuj się z nami: pomoc@pagaspot.com`,
      clientCode: `Jeśli dwóch lub więcej płatników Twojej firmy ma identyczną nazwę 
        (imię i nazwisko) - dodaj identyfikator płatnika, który umożliwi ich rozpoznanie.`,
      requestBankAccountNumber: `Brak aktywnego numeru konta - dodaj numer konta poniżej i poczekaj na akceptację.
        Jeśli to pilne napisz na pomoc@pagaspot.com`,
      notifyToChangeAccount: 'Aby zmienić numer konta wyślij mail na pomoc@pagaspot.com',
      sendRemindersToNotInvited: `Nawet płatnicy którzy nie zostali zaproszeni do Pagaspot
        otrzymają przypomnienia mailowe z podsumowaniem niezapłaconych należności.`,
      sendNewMessagesToNotInvited: `Nawet płatnicy którzy nie zostali zaproszeni do Pagaspot będą otrzymywali 
        mailem wszystkie wiadomości które do nich wyślesz`,
      sendBillUpdatesBySms: `Informacje o nowych należnościach i anulowanych należnościach 
      będziemy wysyłać też jako SMS`,
      sendBillUpdatesToNotInvited: `Nawet płatnicy którzy nie zostali zaproszeni do Pagaspot będą otrzymywali 
        informacje o nowych należnościach i anulacjach naliczeń`,
      sendBillUpdates: 'Płatnicy będą otrzymywali informacje o nowo utworzonych należnościach i anulacjach naliczeń',
      sendRemindersBySms: 'Informacje o niezapłaconych należnościach będziemy wysyłać też SMSem',
      smsCount: `Gdy Pagaspot wysyła SMSa w twoim imieniu, ta liczba się zmniejsza.
        Jeśli twoja wiadomość zawiera polskie znaki 1 SMS to 70 znaków. Jeśli nie używasz polskich
        znaków, 1 SMS to 160 znaków`,
      remindLatePayments: 'Jak często powinniśmy powiadamiać twoich kientów o tym że nie uregulowali należności?',
      sendBillDueDateExpired: 'Gdy termin płatności mija, poinformujemy tych którzy nie uregulowali jeszcze należności',
      companyPaysCommission: `Dodatkowa opłata którą musiałby ponieść płacący zostanie odjęta od kwoty należnej.
        Na konto wpłynie odpowiednio pomniejszona kwota`,
      sendBillingScopeSummary: `W określonym dniu okresu rozliczeniowego wyślemy do każdego płatnika
        dokument zawierający wszystkie naliczenia i aktualne saldo`,
      billingScopeDay: 'dzień okresu rozliczeniowego',
    },
    currencies: {
      currency: 'Waluta',
      currencies: 'Waluty',
      alertNoMoreCurr: 'Nie obsługujemy więcej walut',
      alertDeleteCurr: 'Czy napewno chesz usunąć walutę?',
      infoNbp: 'Użyte kursy zostaly wyciągniete z NPB jeżeli chcesz je zmienić ustaw własne',
      currencyRateInfo: 'Zastosowano kurs:',
      currencyAmountInfo: 'Rozliczymy kwotę:',
      currencySelect: 'Wybierz walute',
      currencyInfo: 'Poinformuj nas jeżeli nie ma tu twojej waluty: support@pagaspot.com',
      currencyErrNoCurrency: 'Musisz mieć ustawioną conajmniej jedną walutę',
      sendError: 'Spróbuj ponownie',
      clickCurrencyInfo: 'Wybierz walute którą chcesz dodać',
    },
    bankAccounts: {
      alertDeleteAcc: 'Czy napewno chesz usunąć rachunek bankowy?',
      addBankAccount: 'Dodaj Rachunek Bankowy',
      typeAccNumber: 'Numer Konta',
      typeAccName: 'Nazwa Rachunku',
      bankAccount: 'Rachunek Bankowy',
      noBankAccount: 'Brak rachunku bankowego',
      accNumber: 'Numer rachunku bankowego',
      prefixAccount: 'Indywidualne Konta',
      currency: 'Waluta',
      waitingToVerify: 'Oczekuje na weryfikacje',
      visible: 'Widoczny dla płatnika',
      verifyDone: 'Zweryfikowano',
      rejected: 'Odrzucono',
      somePayersNotConfigured: 'Niektórzy płatnicy nie mają ustawionego tego numeru konta',
      accountNotValidatedInfo: `Dopóki ten numer konta nie zostanie zwalidowany, 
        nie zostanie on wyświetlony płatnikom a bramka płatnicza będzie zablokowana.
        Napisz na pomoc@pagaspot.com jeśli numer konta pozostaje niezwalidowany`,
      noValidatedAccount: 'Brak zwalidowanego numeru konta',
      noValidatedAccountInfo: `Płatnicy nie będą mogli opłacić tej należności korzystając z bramki płatniczej.
        Dodaj w ustawieniach numer konta i poczekaj na weryfikację lub napisz na pomoc@pagaspot.com`,
      specialCharactersInfo: `Jeśli używasz indywidualnych kont, użyj <strong>_</strong><br>
        zamiast cyfr numeru konta które są zmienne`,
      noAccountWithPlaceholder: `Nie zdefiniowałeś(aś) żadnego konta używającego dynamicznych pól.
        Przejdź do zakładki "Należności" w ustawieniach i dodaj regułę na numer konta używając "_"`,
    },
    contactData: {
      uploadLogo: 'Upuść nowe logo lub kliknij tu aby wybrać z dysku',
      logoUploadError: `Wystąpił błąd przy ustawianiu loga organizacji. 
      Upewnij się że plik jest obrazkiem jpg lub png, i jest mniejszy niż 200 KB`,
    },
  },
  error: {
    error: 'Błąd',
    internalError: '<b>Nie udało się pobrać danych. Spróbuj ponownie później.</b><br>Jeśli problem się powtarza napisz do nas na pomoc@pagaspot.com',
    unknownError: 'Wystąpił błąd. Spróbuj ponownie później. \nJeśli problem się powtarza napisz do nas na pomoc@pagaspot.com',
    fail: 'Nie udało się pobrać danych',
    wrongPassword: 'Błędne hasło!',
    noExcess: 'Brak nadpłaty do zwrotu',
    settingsUnavailable: 'Nie udało się pobrać szczegółów ustawień. Spróbuj ponownie później.',
    enterName: 'Wprowadź imię i nazwisko płatnika!',
    selectPayers: 'Wybierz płatników',
    enterPaymentName: 'Podaj tytuł płatności',
    enterPaymentAmount: 'Podaj kwotę wpłaty',
    invalidAmount: 'Nieprawidłowa kwota',
    importImpossible: 'Import się nie powiódł',
    enterAtLeastThree: 'Wprowadź minimum 3 znaki',
    enterAtLeastEight: 'Wprowadź minimum 8 znaków',
    enterTenDigits: 'Wprowadź 10 cyfr',
    mandatoryField: 'To pole jest obowiązkowe',
    fillMandatoryFields: 'Wypełnij wszystkie obowiązkowe pola',
    emailInfo: 'Adress email musi posiadać @ oraz minimum 5 znaków',
    demoVersionUserLimit: 'Używając konta DEMO możesz dodać maksymalnie 10 użytkowników',
    demoVersionActivateAdmin: 'Używając konta DEMO możesz aktywować tylko adminów',
    type: {
      CodeUsed: 'Kod został już użyty',
      CodeExpired: 'Kod wygasł',
      EmailExists: 'Podany email już istenieje. Wróć do logowania i użyj "Magic Link" jeśli nie pamiętasz hasła',
      UserNotVerified: 'Konto nie zostało zweryfikowane. Kliknij na "Magic Link" żeby zweryfikować adres email',
      Unknown: 'Nieznany błąd',
      InvalidEmail: 'Niepoprawny email',
      InvalidPassword: 'Niepoprawne hasło',
      NoAccess: 'Brak dostępu',
      InvalidCode: 'Niepoprawny kod',
      BillsInChannelExists: 'Istnieją należności przypisane do tego kanału',
      BillsChannelIsDefault: 'Nie możesz usunąć domyślnego kanału',
    },
  },
  time: {
    lessThanDay: 'Mniej niż dzień',
    date: 'Data',
    month: 'Miesiąc',
    quarter: 'Kwartał',
    hrs: 'godz',
    year: 'Rok',
    period: 'Okres',
    months: {
      zero: 'Miesięcy',
      one: 'Miesiąc',
      few: 'Miesiące',
      many: 'Miesięcy',
    },
    day: 'Dzień',
    days: 'Dni',
    week: 'Tydzień',
    weeks: {
      zero: 'Tygodni',
      one: 'Tydzień',
      few: 'Tygodnie',
      many: 'Tygodni',
    },
    desc: {
      day: `Płatność nie będzie naliczana 
      | Płatność będzie naliczana codziennie 
      | Płatność będzie naliczana co {n} dni`,
      week: {
        zero: 'Płatność nie będzie naliczana',
        one: 'Płatność będzie naliczana w każdy {weekday}',
        few: 'Płatność będzie naliczana w {weekday}, co {n} tygodnie',
        many: 'Płatność będzie naliczana w {weekday}, co {n} tygodni',
      },
      month: {
        zero: 'Płatność nie będzie naliczana',
        one: 'Płatność będzie naliczana {day}-go dnia każdego miesiąca',
        few: 'Płatność będzie naliczana {day}-go co {n} miesiące',
        many: 'Płatność będzie naliczana {day}-go co {n} miesięcy',
      },
    },
  },
  messages: {
    newMessage: 'Nowa wiadomość',
    message: 'Wiadomość',
    send: 'Wyślij',
    sendNow: 'Wyślij teraz',
    skip: 'Pomiń',
    skipped: 'Pominięte',
    noThreads: 'Brak wątków',
    reply: 'Odpowiedz',
    replyAll: 'Napisz wszystkim',
    replySelected: 'Napisz wybranym',
    selectCompany: 'Wybierz organizację',
    title: 'Tytuł wiadomości',
    sendAsSms: 'Wyślij jako SMS',
    characters: 'znaków',
    markAsUnread: 'Oznacz jako nieprzeczytana',
    numberOfSmsForThisMessage: 'Liczba SMS do wysłania',
    remainingSmsMessages: 'Pozostałe wiadomości SMS w tym miesiącu',
    selectOrStartNew: 'Wybierz lub rozpocznij nowy wątek',
    threadStatus: 'Status wątku',
    threadIsClosed: 'Wątek jest zamknięty. Aby napisać nową wiadomość musisz go najpierw otworzyć korzystając z akcji na górze',
    statusChange: {
      open: 'Otwórz',
      close: 'Zamknij',
    },
    status: {
      open: 'Aktywne',
      closed: 'Zamknięte',
    },
    error: {
      unableToLoadThreads: 'Nie udało się pobrać wątków',
    },
  },
  import: {
    file: 'Plik',
    upload: 'Upuść plik tutaj aby zaimportować, lub kliknij by wybrać',
    unableToImport: `<strong>Import pliku się nie powiódł</strong><br> Sprawdź czy format jest prawidłowy (np. czy nie był modyfikowany)
     spróbuj ponownie lub skontaktuj się z nami wysyłając plik na pomoc@pagaspot.com`,
    allSettled: 'Wszystkie wprowadzone przelewy w tym roku zostały rozliczone! Skorzystaj z pola wyżej aby zaimportować nowy plik',
    noTransfers: 'Nie znaleziono przelewów',
    expectedFormat: 'Oczekiwany format pliku:',
    alreadySettled: 'Został już rozliczony!',
    resetFormat: 'Resetuj format',
    unsettled: 'Nierozliczone',
    ignored: 'Pominięte',
    settled: 'Rozliczone',
    matched: 'Dopasowane',
    unmatched: 'Niedopasowane',
    setGroups: 'Ustaw grupy',
    thereAreSomeIssues: 'Znaleźliśmy kilka problemów z tym plikiem',
    noIssues: 'Wszystko gra! Przejdź dalej aby zapisać dane',
    noImport: 'Nie importuj tych kolumn',
    imported: 'Zaimportowano',
    errors: 'Błędy',
    new: 'Nowe',
    updated: 'Aktualizacja',
    unchanged: 'Nie zmienione',
    duplicates: 'Duplikaty',
    moveHereToIgnore: 'Przenieś kolumnę tutaj aby pominąć',
    columnsInfo: 'Ułóż kolumny łapiąc i przeciągając, tak aby odpowiadały kolejności kolumn w pliku źródłowym',
    duplicateDectionPerson: 'Płatnik zostanie rozpoznany bazując na imieniu, nazwisku i kodzie płatnika (jeśli został zaimportowany)',
    duplicateDectionOrganization: 'Płatnik zostanie rozpoznany bazując na nazwie i nip-ie (jeśli został zaimportowany)',
    loading: {
      1: 'Analizujemy twój plik',
      2: 'Sprawdzamy czy nie ma duplikatów',
      3: 'Porównujemy do innych transakcji',
      4: 'Dopasowujemy płatników',
      5: 'Przygotowujemy do wyświetlenia',
    },
  },
  notify: {
    pendingNotifications: 'Powiadomienia oczekujące na wysłanie',
    sentNotifications: 'Wysłane powiadomienia',
    willNotReceive: `Bazując na aktualnych ustawieniach i statusie aktywacji, 
      to powiadomienie nie zostanie wysłane do tego płatnika`,
    skipped: 'Powiadomienie zostało pominięte. Żaden płatnik nie otrzymał wiadomości',
    nooneWillReceiveIt: 'Żaden z płatników nie otrzyma tego powiadomienia',
    nooneReceivedIt: 'Żaden z płatników nie otrzymał tego powiadomienia',
    nooneWillReceiveItHelp: `Upewnij się że dane kontaktowe są wypełnione a płatnicy aktywowani,
     lub czy wysyłanie tego typu powiadomień do niezarejestrowanych jest włączone`,
    plannedDelivery: 'Planowana wysyłka',
    delivered: 'Wysłane',
    skippedDelivery: 'Pominięte',
    push: 'Powiadomienie Push',
    notDelivered: 'Nie wysłane',
    type: {
      BillCancelledNotification: 'Należność anulowana',
      BillCreatedNotification: 'Należność utworzona',
      MessageSentNotification: 'Nowa wiadomość',
      BillDueDateExpiredNotification: 'Termin należności mija',
      BillsReminderNotification: 'Oczekujące zaległości',
      BillingScopeSummaryNotification: 'Podsumowanie okresu rozliczeniowego',
      DocumentDeliveryNotification: 'Wysyłka dokumentów',
    },
  },
  enrollements: {
    newEnrollements: 'Nowe wnioski',
    removals: 'Wykreślenia',
    approvals: 'Zaakceptowane wnioski',
    add: 'Dodaj wniosek',
    date: 'Zgłoszono',
    status: {
      status: 'Status',
      waitingForPayment: 'Oczekuje na wpłatę',
      paid: 'Wpłacono',
    },
  },
  page: {
    visableColumns: 'Widoczne kolumny',
    hiddenColumns: 'Ukryte kolumny',
    availableColumns: 'Dostępne kolumny',
    space: '[ - przerwa - ]',
  },
};
